import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../images/splash_lottie.json";
import "./SplashScreen.css";

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: "100%", width: "100%" }}
      />
    </div>
  );
};

export default SplashScreen;
