import React from "react";
import { FaCheck } from "react-icons/fa";
import "./SortPanel.css";
import {
  trackButtonClick,
  usePageViews,
  useDocumentTitle,
} from "../../utils/analytics";

const SortPage = ({ selectedOption, updateSelection, activeTab }) => {
  usePageViews("Sort Page");

  const sortOptions = [
    "Available items only",
    "Largest price gap",
    "Most reviews",
    "Lowest to highest price",
    "Highest to lowest price",
  ].filter((option) => option !== "Available items only");

  const handleOptionClick = (option) => {
    if (option === "Available items only") {
      const newAvailableItemsOnlyState = !selectedOption.availableItemsOnly;
      updateSelection({
        ...selectedOption,
        availableItemsOnly: newAvailableItemsOnlyState,
      });
      trackButtonClick(
        "Sort selected",
        option +
          " " +
          (newAvailableItemsOnlyState ? "On" : "Off") +
          " in " +
          activeTab +
          " tab"
      );
    } else {
      const newSortOrder = selectedOption.sortOrder === option ? null : option;
      updateSelection({
        ...selectedOption,
        sortOrder: newSortOrder,
      });
      trackButtonClick(
        "Sort selected",
        option +
          " " +
          (newSortOrder ? "On" : "Off") +
          " in " +
          activeTab +
          " tab"
      );
    }
  };

  return (
    <div className="sort-page">
      <h2>Sort By</h2>
      <ul>
        {sortOptions.map((option, index) => (
          <li
            key={index}
            className={`sort-option ${
              (option === "Available items only" &&
                selectedOption.availableItemsOnly) ||
              (option !== "Available items only" &&
                selectedOption.sortOrder === option)
                ? "selected"
                : ""
            }`}
            onClick={() => handleOptionClick(option)}
          >
            {option}
            {(option === "Available items only" &&
              selectedOption.availableItemsOnly) ||
            (option !== "Available items only" &&
              selectedOption.sortOrder === option) ? (
              <FaCheck
                style={{
                  position: "absolute",
                  right: "10px",
                  fontSize: "16px",
                  color: "#007bff",
                }}
              />
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SortPage;
