/* eslint-disable */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  Container,
  Navbar,
  Nav,
  Spinner,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import ExactPage from "../components/ExactPage";
import SimilarPage from "../components/SimilarPage";
import {
  getExactItemsByCategory,
  getSimilarItemsByCategory,
  postFeedback,
} from "../apiService";
import "./App.css";
import { WishlistProvider, WishlistContext } from "../context/WishlistContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import WishlistPage from "../components/Wishlist/WishlistPage";
import SortPanel from "../components/SortPanel/SortPanel";
import { FaHeart, FaRegComment } from "react-icons/fa";
import WishlistModal from "../components/Wishlist/WishlistModal";
import FilterPanel, {
  CATEGORY_ENUM,
} from "../components/FilterPanel/FilterPanel";
import DisplayBoard from "../components/DisplayBoard/DisplayBoard";
import { BiSortAlt2 } from "react-icons/bi";
import SplashScreen from "../components/SplashScreen/SplashScreen";
import {
  trackButtonClick,
  usePageViews,
  useDocumentTitle,
} from "../utils/analytics";
import SortPage from "../components/SortPanel/SortPage";

function WinterClothingSimilar() {
  useDocumentTitle("MatchDeal");
  usePageViews("MatchDeal");

  const [activeTab, setActiveTab] = useState("Similar");
  // State for SimilarPage
  const [similarProducts, setSimilarProducts] = useState({
    [CATEGORY_ENUM.WINTER_CLOTHING]: [],
  });
  const [similarStartIndex, setSimilarStartIndex] = useState({
    [CATEGORY_ENUM.WINTER_CLOTHING]: 0,
  });
  const [similarTotalItemsCount, setSimilarTotalItemsCount] = useState({
    [CATEGORY_ENUM.WINTER_CLOTHING]: 1,
  });
  const [similarScrollPositions, setSimilarScrollPositions] = useState({
    [CATEGORY_ENUM.WINTER_CLOTHING]: 0,
  });
  const lastSimilarScrollTop = useRef({
    [CATEGORY_ENUM.WINTER_CLOTHING]: 0,
  });

  const [similarSelection, setSimilarSelection] = useState({
    selectedOption: {
      availableItemsOnly: false,
      sortOrder: null,
    },
    currentCategory: CATEGORY_ENUM.WINTER_CLOTHING,
  });

  const updateSimilarSelection = (newSelection) => {
    setSimilarSelection((prevSelection) => {
      if (
        newSelection.selectedOption !== undefined &&
        newSelection.selectedOption !== prevSelection.selectedOption
      ) {
        Object.values(CATEGORY_ENUM).forEach((category) => {
          setSimilarScrollPositions((prevPositions) => ({
            ...prevPositions,
            [category]: 0,
          }));
          setSimilarProducts((prevProducts) => ({
            ...prevProducts,
            [category]: [],
          }));
          setSimilarTotalItemsCount((prevCount) => ({
            ...prevCount,
            [category]: 1,
          }));
          setSimilarStartIndex((prevIndex) => ({
            ...prevIndex,
            [category]: 0,
          }));
        });
        isSimilarSelectionChanged.current = true;
      }
      return { ...prevSelection, ...newSelection };
    });
  };

  const [isFetchingSimilar, setIsFetchingSimilar] = useState(false);
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const isFirstLaunch = useRef(true);
  const isSimilarSelectionChanged = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const observer = useRef();
  const pageLimit = 5;

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [contactMessage, setContactMessage] = useState("");
  const [contactInfo, setContactInfo] = useState("");

  // Function to get local time in a readable format
  function getLocalTime() {
    const now = new Date();
    return now.toLocaleString(); // Returns the local time as a string
  }

  // Function to get local region
  function getLocalRegion() {
    const region = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    return region; // Returns the local region (e.g., "America/Los_Angeles")
  }

  const handleContactSubmit = () => {
    // Handle the contact form submission logic here
    postFeedback(contactMessage, contactInfo, getLocalTime(), getLocalRegion());
    setIsContactModalOpen(false);
    setContactMessage("");
    setContactInfo("");
    alert("Thank you for your feedback!");
  };

  const fetchSimilarProducts = useCallback(
    async (
      category = similarSelection.currentCategory,
      isInitialFetch = false
    ) => {
      if (
        isFetchingSimilar ||
        similarStartIndex[category] >= similarTotalItemsCount[category]
      )
        return;
      setIsFetchingSimilar(true);

      try {
        const response = await getSimilarItemsByCategory(
          similarStartIndex[category],
          pageLimit,
          category,
          similarSelection.selectedOption.availableItemsOnly,
          similarSelection.selectedOption.sortOrder,
          "similar_test_20241120",
          "20241120"
        );
        const { items, totalItems } = response;
        setSimilarProducts((prevProducts) => ({
          ...prevProducts,
          [category]: [...prevProducts[category], ...items],
        }));
        if (similarStartIndex[category] === 0) {
          setSimilarTotalItemsCount((prevCount) => ({
            ...prevCount,
            [category]: totalItems,
          }));
        }
        setSimilarStartIndex((prevIndex) => ({
          ...prevIndex,
          [category]: prevIndex[category] + items.length,
        }));
      } catch (error) {
        console.error("Error fetching similar products:", error);
      } finally {
        if (!isInitialFetch) {
          setIsFetchingSimilar(false);
        }
      }
    }
  );

  useEffect(() => {
    const container = document.querySelector(".app-container");
    if (container && activeTab === "Similar") {
      if (similarProducts[similarSelection.currentCategory].length > 0) {
        container.scrollTop =
          similarScrollPositions[similarSelection.currentCategory];
      }

      container.addEventListener("scroll", handleSimilarScroll);
      return () => container.removeEventListener("scroll", handleSimilarScroll);
    }
  }, [similarSelection, isLoading, activeTab]);

  useEffect(() => {
    isFirstLaunch.current = false;

    const fetchSimilarInitialData = async () => {
      await Promise.all(
        Object.values(CATEGORY_ENUM).map((category) =>
          fetchSimilarProducts(category, true)
        )
      );
      setIsFetchingSimilar(false);
      setIsLoading(false);
    };

    if (isSimilarSelectionChanged.current) {
      isSimilarSelectionChanged.current = false;
      fetchSimilarInitialData();
    } else {
      if (
        activeTab === "Similar" &&
        (similarStartIndex[similarSelection.currentCategory] === 0 ||
          similarStartIndex[similarSelection.currentCategory] <
            similarTotalItemsCount[similarSelection.currentCategory])
      ) {
        fetchSimilarProducts();
        setIsLoading(false);
      }
    }
  }, [activeTab, similarSelection]);

  const lastSimilarProductElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (
        !isFetchingSimilar &&
        entries[0].isIntersecting &&
        similarStartIndex[similarSelection.currentCategory] <
          similarTotalItemsCount[similarSelection.currentCategory]
      ) {
        fetchSimilarProducts();
      }
    });
    if (node) observer.current.observe(node);
  });

  const handleSimilarScroll = () => {
    if (activeTab !== "Similar") return;
    const container = document.querySelector(".app-container");
    const scrollTop = container.scrollTop;

    setSimilarScrollPositions((prevPositions) => ({
      ...prevPositions,
      [similarSelection.currentCategory]: scrollTop,
    }));

    // Update lastSimilarScrollTop to manage each category separately
    if (
      scrollTop >
        lastSimilarScrollTop.current[similarSelection.currentCategory] &&
      scrollTop > 20
    ) {
      setIsScrollingUp(false);
    } else {
      setIsScrollingUp(true);
    }
    lastSimilarScrollTop.current = {
      ...lastSimilarScrollTop.current,
      [similarSelection.currentCategory]: scrollTop,
    };
  };

  const LoadingSpinner = () => (
    <div className="loading-spinner">
      <Spinner
        animation="border"
        role="status"
        variant="dark"
        style={{ width: "2rem", height: "2rem" }}
      ></Spinner>
    </div>
  );

  return (
    <WishlistProvider>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <>
          <Navbar
            bg="light"
            variant="light"
            sticky="top"
            className="align-items-start"
          >
            <Container fluid>
              <Navbar.Brand
                onClick={() => {
                  const container = document.querySelector(".app-container");
                  if (container) {
                    if (
                      activeTab === "Similar" &&
                      similarProducts[similarSelection.currentCategory].length >
                        0
                    ) {
                      container.scrollTo({ top: 0, behavior: "smooth" });
                      handleSimilarScroll();
                    }
                  }
                }}
              >
                <span className="brand-match">Match</span>
                <span className="brand-deal">Deal</span>
                <span className="single-liner">for smart shoppers</span>
              </Navbar.Brand>
              <Nav className="top-nav"></Nav>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                }}
              >
                <FaRegComment
                  className="contact-icon"
                  onClick={() => setIsContactModalOpen(true)}
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                    color: "black",
                  }}
                />
                <BiSortAlt2
                  className="sort-icon"
                  onClick={() => {
                    setIsSortOpen(true);
                    trackButtonClick("Sort opened", activeTab);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "black",
                  }}
                />
              </div>
            </Container>
          </Navbar>
          <Modal
            show={isContactModalOpen}
            onHide={() => setIsContactModalOpen(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  controlId="contactForm.ControlTextarea"
                  style={{ marginBottom: "15px" }}
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Complaints or suggestions—we appreciate your feedback! :)"
                    rows={3}
                    value={contactMessage}
                    onChange={(e) => setContactMessage(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="contactForm.ControlInput">
                  <Form.Control
                    type="text"
                    placeholder="(Optional) Your email—we’d love to connect!"
                    value={contactInfo}
                    onChange={(e) => setContactInfo(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setIsContactModalOpen(false)}
              >
                Close
              </Button>
              <Button variant="primary" onClick={handleContactSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="app-wrapper">
            <div className="mobile-container">
              <div
                className={`app-container ${activeTab.toLowerCase()}-background`}
              >
                <div className="content-wrapper">
                  <Container className="mt-4">
                    <SimilarPage
                      products={
                        similarProducts[similarSelection.currentCategory]
                      }
                      lastProductElementRef={lastSimilarProductElementRef}
                      isFetchingSimilar={isFetchingSimilar}
                    />
                    {isFetchingSimilar && <LoadingSpinner />}
                  </Container>
                </div>
                {/* <FloatingWishlistIcon
                  onClick={() => {
                    setIsWishlistOpen(true);
                    trackButtonClick("Wishlist opened", activeTab);
                  }}
                /> */}
              </div>
            </div>
          </div>
          <Routes>
            <Route path="/wishlist" element={<WishlistPage />} />
            <Route path="/sort" element={<SortPage />} />
            <Route path="/similar" element={<SimilarPage />} />
          </Routes>
          <WishlistModal
            isOpen={isWishlistOpen}
            onClose={() => {
              setIsWishlistOpen(false);
              trackButtonClick("Wishlist closed", activeTab);
            }}
          />
          <SortPanel
            isOpen={isSortOpen}
            onClick={() => {
              setIsSortOpen(false);
              trackButtonClick("Sort closed", activeTab);
            }}
            selectedOption={similarSelection.selectedOption}
            updateSelection={updateSimilarSelection}
            activeTab={activeTab}
          />
        </>
      )}
    </WishlistProvider>
  );
}

const FloatingWishlistIcon = ({ onClick }) => {
  const { wishlist } = useContext(WishlistContext);

  return (
    <div onClick={onClick} className="floating-wishlist-icon">
      <FaHeart />
      <span className="wishlist-count">{wishlist.length}</span>
    </div>
  );
};

export default WinterClothingSimilar;
