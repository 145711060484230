/* eslint-disable */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Container, Navbar, Nav, Spinner } from "react-bootstrap";
import { useSwipeable } from "react-swipeable";
import ExactPage from "../components/ExactPage";
import SimilarPage from "../components/SimilarPage";
import {
  getExactItemsByCategory,
  getSimilarItemsByCategory,
} from "../apiService";
import "./App.css";
import { WishlistProvider, WishlistContext } from "../context/WishlistContext";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import WishlistPage from "../components/Wishlist/WishlistPage";
import SortPanel from "../components/SortPanel/SortPanel";
import { FaHeart } from "react-icons/fa";
import WishlistModal from "../components/Wishlist/WishlistModal";
import FilterPanel, {
  CATEGORY_ENUM,
} from "../components/FilterPanel/FilterPanel";
import DisplayBoard from "../components/DisplayBoard/DisplayBoard";
import { BiSortAlt2 } from "react-icons/bi";
import SplashScreen from "../components/SplashScreen/SplashScreen";
import {
  trackButtonClick,
  usePageViews,
  useDocumentTitle,
} from "../utils/analytics";
import SortPage from "../components/SortPanel/SortPage";

function App() {
  useDocumentTitle("MatchDeal");
  usePageViews("MatchDeal");

  const [activeTab, setActiveTab] = useState("Similar");
  // State for ExactPage
  const [exactProducts, setExactProducts] = useState({
    [CATEGORY_ENUM.ALL]: [],
    [CATEGORY_ENUM.LIVING]: [],
    [CATEGORY_ENUM.FASHION_BEAUTY]: [],
    [CATEGORY_ENUM.ELECTRONICS]: [],
  });
  const [exactStartIndex, setExactStartIndex] = useState({
    [CATEGORY_ENUM.ALL]: 0,
    [CATEGORY_ENUM.LIVING]: 0,
    [CATEGORY_ENUM.FASHION_BEAUTY]: 0,
    [CATEGORY_ENUM.ELECTRONICS]: 0,
  });
  const [exactTotalItemsCount, setExactTotalItemsCount] = useState({
    [CATEGORY_ENUM.ALL]: 1,
    [CATEGORY_ENUM.LIVING]: 1,
    [CATEGORY_ENUM.FASHION_BEAUTY]: 1,
    [CATEGORY_ENUM.ELECTRONICS]: 1,
  });
  const [exactScrollPositions, setExactScrollPositions] = useState({
    [CATEGORY_ENUM.ALL]: 0,
    [CATEGORY_ENUM.LIVING]: 0,
    [CATEGORY_ENUM.FASHION_BEAUTY]: 0,
    [CATEGORY_ENUM.ELECTRONICS]: 0,
  });
  const lastExactScrollTop = useRef({
    [CATEGORY_ENUM.ALL]: 0,
    [CATEGORY_ENUM.LIVING]: 0,
    [CATEGORY_ENUM.FASHION_BEAUTY]: 0,
    [CATEGORY_ENUM.ELECTRONICS]: 0,
  });
  // State for SimilarPage
  const [similarProducts, setSimilarProducts] = useState({
    // [CATEGORY_ENUM.ALL]: [],
    [CATEGORY_ENUM.LIVING]: [],
    [CATEGORY_ENUM.FASHION_BEAUTY]: [],
    // [CATEGORY_ENUM.ELECTRONICS]: [],
  });
  const [similarStartIndex, setSimilarStartIndex] = useState({
    // [CATEGORY_ENUM.ALL]: 0,
    [CATEGORY_ENUM.LIVING]: 0,
    [CATEGORY_ENUM.FASHION_BEAUTY]: 0,
    // [CATEGORY_ENUM.ELECTRONICS]: 0,
  });
  const [similarTotalItemsCount, setSimilarTotalItemsCount] = useState({
    // [CATEGORY_ENUM.ALL]: 1,
    [CATEGORY_ENUM.LIVING]: 1,
    [CATEGORY_ENUM.FASHION_BEAUTY]: 1,
    // [CATEGORY_ENUM.ELECTRONICS]: 1,
  });
  const [similarScrollPositions, setSimilarScrollPositions] = useState({
    // [CATEGORY_ENUM.ALL]: 0,
    [CATEGORY_ENUM.LIVING]: 0,
    [CATEGORY_ENUM.FASHION_BEAUTY]: 0,
    // [CATEGORY_ENUM.ELECTRONICS]: 0,
  });
  const lastSimilarScrollTop = useRef({
    // [CATEGORY_ENUM.ALL]: 0,
    [CATEGORY_ENUM.LIVING]: 0,
    [CATEGORY_ENUM.FASHION_BEAUTY]: 0,
    // [CATEGORY_ENUM.ELECTRONICS]: 0,
  });

  const [exactSelection, setExactSelection] = useState({
    selectedOption: {
      availableItemsOnly: false,
      sortOrder: null,
    },
    currentCategory: CATEGORY_ENUM.ALL,
  });

  const [similarSelection, setSimilarSelection] = useState({
    selectedOption: {
      availableItemsOnly: false,
      sortOrder: null,
    },
    currentCategory: CATEGORY_ENUM.FASHION_BEAUTY,
  });

  const updateExactSelection = (newSelection) => {
    setExactSelection((prevSelection) => {
      if (
        newSelection.selectedOption !== undefined &&
        newSelection.selectedOption !== prevSelection.selectedOption
      ) {
        Object.values(CATEGORY_ENUM).forEach((category) => {
          setExactScrollPositions((prevPositions) => ({
            ...prevPositions,
            [category]: 0,
          }));
          setExactProducts((prevProducts) => ({
            ...prevProducts,
            [category]: [],
          }));
          setExactTotalItemsCount((prevCount) => ({
            ...prevCount,
            [category]: 1,
          }));
          setExactStartIndex((prevIndex) => ({
            ...prevIndex,
            [category]: 0,
          }));
        });
        isExactSelectionChanged.current = true;
      }
      return { ...prevSelection, ...newSelection };
    });
  };

  const updateSimilarSelection = (newSelection) => {
    setSimilarSelection((prevSelection) => {
      if (
        newSelection.selectedOption !== undefined &&
        newSelection.selectedOption !== prevSelection.selectedOption
      ) {
        Object.values(CATEGORY_ENUM).forEach((category) => {
          setSimilarScrollPositions((prevPositions) => ({
            ...prevPositions,
            [category]: 0,
          }));
          setSimilarProducts((prevProducts) => ({
            ...prevProducts,
            [category]: [],
          }));
          setSimilarTotalItemsCount((prevCount) => ({
            ...prevCount,
            [category]: 1,
          }));
          setSimilarStartIndex((prevIndex) => ({
            ...prevIndex,
            [category]: 0,
          }));
        });
        isSimilarSelectionChanged.current = true;
      }
      return { ...prevSelection, ...newSelection };
    });
  };

  const [isFetchingExact, setIsFetchingExact] = useState(false);
  const [isFetchingSimilar, setIsFetchingSimilar] = useState(false);
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const isFirstLaunch = useRef(true);
  const isExactSelectionChanged = useRef(false);
  const isSimilarSelectionChanged = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const observer = useRef();
  const pageLimit = 5;

  const fetchExactProducts = useCallback(
    async (
      category = exactSelection.currentCategory,
      isInitialFetch = false
    ) => {
      if (
        isFetchingExact ||
        exactStartIndex[category] >= exactTotalItemsCount[category]
      )
        return;
      setIsFetchingExact(true);
      try {
        const response = await getExactItemsByCategory(
          exactStartIndex[category],
          pageLimit,
          category,
          exactSelection.selectedOption.availableItemsOnly,
          exactSelection.selectedOption.sortOrder
        );
        const { items, totalItems } = response;
        setExactProducts((prevProducts) => ({
          ...prevProducts,
          [category]: [...prevProducts[category], ...items],
        }));
        if (exactStartIndex[category] === 0) {
          setExactTotalItemsCount((prevCount) => ({
            ...prevCount,
            [category]: totalItems,
          }));
        }
        setExactStartIndex((prevIndex) => ({
          ...prevIndex,
          [category]: prevIndex[category] + items.length,
        }));
      } catch (error) {
        console.error("Error fetching exact products:", error);
      } finally {
        if (!isInitialFetch) {
          setIsFetchingExact(false);
        }
      }
    }
  );

  const fetchSimilarProducts = useCallback(
    async (
      category = similarSelection.currentCategory,
      isInitialFetch = false
    ) => {
      if (
        isFetchingSimilar ||
        similarStartIndex[category] >= similarTotalItemsCount[category]
      )
        return;
      setIsFetchingSimilar(true);

      try {
        const response = await getSimilarItemsByCategory(
          similarStartIndex[category],
          pageLimit,
          category,
          similarSelection.selectedOption.availableItemsOnly,
          similarSelection.selectedOption.sortOrder
        );
        const { items, totalItems } = response;
        setSimilarProducts((prevProducts) => ({
          ...prevProducts,
          [category]: [...prevProducts[category], ...items],
        }));
        if (similarStartIndex[category] === 0) {
          setSimilarTotalItemsCount((prevCount) => ({
            ...prevCount,
            [category]: totalItems,
          }));
        }
        setSimilarStartIndex((prevIndex) => ({
          ...prevIndex,
          [category]: prevIndex[category] + items.length,
        }));
      } catch (error) {
        console.error("Error fetching similar products:", error);
      } finally {
        if (!isInitialFetch) {
          setIsFetchingSimilar(false);
        }
      }
    }
  );

  useEffect(() => {
    const container = document.querySelector(".app-container");
    if (container && activeTab === "Exact") {
      if (exactProducts[exactSelection.currentCategory].length > 0) {
        container.scrollTop =
          exactScrollPositions[exactSelection.currentCategory];
      }

      container.addEventListener("scroll", handleExactScroll);
      return () => container.removeEventListener("scroll", handleExactScroll);
    } else if (container && activeTab === "Similar") {
      if (similarProducts[similarSelection.currentCategory].length > 0) {
        container.scrollTop =
          similarScrollPositions[similarSelection.currentCategory];
      }

      container.addEventListener("scroll", handleSimilarScroll);
      return () => container.removeEventListener("scroll", handleSimilarScroll);
    }
  }, [exactSelection, similarSelection, isLoading, activeTab]);

  useEffect(() => {
    isFirstLaunch.current = false;

    const fetchInitialData = async () => {
      await Promise.all(
        Object.values(CATEGORY_ENUM).map((category) =>
          fetchExactProducts(category, true)
        )
      );
      setIsFetchingExact(false);
      setIsLoading(false);
    };

    const fetchSimilarInitialData = async () => {
      await Promise.all(
        Object.values(CATEGORY_ENUM).map((category) =>
          fetchSimilarProducts(category, true)
        )
      );
      setIsFetchingSimilar(false);
      setIsLoading(false);
    };

    if (isFirstLaunch.current || isExactSelectionChanged.current) {
      isFirstLaunch.current = false;
      isExactSelectionChanged.current = false;
      fetchInitialData();
    } else if (isSimilarSelectionChanged.current) {
      isSimilarSelectionChanged.current = false;
      fetchSimilarInitialData();
    } else {
      if (
        activeTab === "Exact" &&
        (exactStartIndex[exactSelection.currentCategory] === 0 ||
          exactStartIndex[exactSelection.currentCategory] <
            exactTotalItemsCount[exactSelection.currentCategory])
      ) {
        fetchExactProducts();
      } else if (
        activeTab === "Similar" &&
        (similarStartIndex[similarSelection.currentCategory] === 0 ||
          similarStartIndex[similarSelection.currentCategory] <
            similarTotalItemsCount[similarSelection.currentCategory])
      ) {
        fetchSimilarProducts();
        setIsLoading(false);
      }
    }
  }, [activeTab, exactSelection, similarSelection]);

  const lastExactProductElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (
        !isFetchingExact &&
        entries[0].isIntersecting &&
        exactStartIndex[exactSelection.currentCategory] <
          exactTotalItemsCount[exactSelection.currentCategory]
      ) {
        fetchExactProducts();
      }
    });
    if (node) observer.current.observe(node);
  });

  const lastSimilarProductElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (
        !isFetchingSimilar &&
        entries[0].isIntersecting &&
        similarStartIndex[similarSelection.currentCategory] <
          similarTotalItemsCount[similarSelection.currentCategory]
      ) {
        fetchSimilarProducts();
      }
    });
    if (node) observer.current.observe(node);
  });

  const handleExactScroll = () => {
    if (activeTab !== "Exact") return;
    const container = document.querySelector(".app-container");
    const scrollTop = container.scrollTop;
    const isAtBottom =
      scrollTop + container.clientHeight >= container.scrollHeight;

    setExactScrollPositions((prevPositions) => ({
      ...prevPositions,
      [exactSelection.currentCategory]: scrollTop,
    }));

    // Update lastExactScrollTop to manage each category separately
    if (
      scrollTop > lastExactScrollTop.current[exactSelection.currentCategory] &&
      scrollTop > 20
    ) {
      setIsScrollingUp(false);
    } else {
      setIsScrollingUp(true);
    }
    lastExactScrollTop.current = {
      ...lastExactScrollTop.current,
      [exactSelection.currentCategory]: scrollTop,
    };
  };

  const handleSimilarScroll = () => {
    if (activeTab !== "Similar") return;
    const container = document.querySelector(".app-container");
    const scrollTop = container.scrollTop;

    setSimilarScrollPositions((prevPositions) => ({
      ...prevPositions,
      [similarSelection.currentCategory]: scrollTop,
    }));

    // Update lastSimilarScrollTop to manage each category separately
    if (
      scrollTop >
        lastSimilarScrollTop.current[similarSelection.currentCategory] &&
      scrollTop > 20
    ) {
      setIsScrollingUp(false);
    } else {
      setIsScrollingUp(true);
    }
    lastSimilarScrollTop.current = {
      ...lastSimilarScrollTop.current,
      [similarSelection.currentCategory]: scrollTop,
    };
  };

  const LoadingSpinner = () => (
    <div className="loading-spinner">
      <Spinner
        animation="border"
        role="status"
        variant="dark"
        style={{ width: "2rem", height: "2rem" }}
      ></Spinner>
    </div>
  );

  return (
    <WishlistProvider>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <>
          <Navbar
            bg="light"
            variant="light"
            sticky="top"
            className="align-items-start"
          >
            <Container fluid>
              <Navbar.Brand
                onClick={() => {
                  const container = document.querySelector(".app-container");
                  if (container) {
                    if (
                      activeTab === "Exact" &&
                      exactProducts[exactSelection.currentCategory].length > 0
                    ) {
                      container.scrollTo({ top: 0, behavior: "smooth" });
                      handleExactScroll();
                    } else if (
                      activeTab === "Similar" &&
                      similarProducts[similarSelection.currentCategory].length >
                        0
                    ) {
                      container.scrollTo({ top: 0, behavior: "smooth" });
                      handleSimilarScroll();
                    }
                  }
                }}
              >
                <span className="brand-match">Match</span>
                <span className="brand-deal">Deal</span>
                <span className="single-liner">for smart shoppers</span>
              </Navbar.Brand>
              <Nav className="top-nav">
                {/* <Nav.Link
                  active={activeTab === "Exact"}
                  onClick={() => {
                    setActiveTab("Exact");
                    trackButtonClick("Exact Tab Clicked", "Viewing Exact Tab");
                  }}
                >
                  Exact
                </Nav.Link> */}
                {/* <Nav.Link
                  active={activeTab === "Similar"}
                  onClick={() => {
                    setActiveTab("Similar");
                    trackButtonClick(
                      "Similar Tab Clicked",
                      "Viewing Similar Tab"
                    );
                  }}
                >
                  Similar
                </Nav.Link> */}
              </Nav>
              <BiSortAlt2
                className="sort-icon"
                onClick={() => {
                  setIsSortOpen(true);
                  trackButtonClick("Sort opened", activeTab);
                }}
                style={{
                  cursor: "pointer",
                  marginLeft: "auto",
                  color: "black",
                }}
              />
            </Container>
          </Navbar>
          {activeTab === "Exact" ? (
            <FilterPanel
              currentCategory={exactSelection.currentCategory}
              updateSelection={updateExactSelection}
              activeTab={activeTab.toLowerCase()}
            />
          ) : (
            <FilterPanel
              currentCategory={similarSelection.currentCategory}
              updateSelection={updateSimilarSelection}
              activeTab={activeTab.toLowerCase()}
            />
          )}
          <div className="app-wrapper">
            <div className="mobile-container">
              <div
                className={`app-container ${activeTab.toLowerCase()}-background`}
              >
                <div className="content-wrapper">
                  <Container className="mt-4">
                    {activeTab === "Exact" ? (
                      <ExactPage
                        products={exactProducts[exactSelection.currentCategory]}
                        lastProductElementRef={lastExactProductElementRef}
                        isFetchingExact={isFetchingExact}
                      />
                    ) : (
                      <SimilarPage
                        products={
                          similarProducts[similarSelection.currentCategory]
                        }
                        lastProductElementRef={lastSimilarProductElementRef}
                        isFetchingSimilar={isFetchingSimilar}
                      />
                    )}
                    {(isFetchingExact || isFetchingSimilar) && (
                      <LoadingSpinner />
                    )}
                  </Container>
                </div>
                <FloatingWishlistIcon
                  onClick={() => {
                    setIsWishlistOpen(true);
                    trackButtonClick("Wishlist opened", activeTab);
                  }}
                />
              </div>
            </div>
          </div>
          <Routes>
            <Route path="/wishlist" element={<WishlistPage />} />
            <Route path="/sort" element={<SortPage />} />
            <Route path="/exact" element={<ExactPage />} />
            <Route path="/similar" element={<SimilarPage />} />
          </Routes>
          <WishlistModal
            isOpen={isWishlistOpen}
            onClose={() => {
              setIsWishlistOpen(false);
              trackButtonClick("Wishlist closed", activeTab);
            }}
          />
          {activeTab === "Exact" ? (
            <SortPanel
              isOpen={isSortOpen}
              onClick={() => {
                setIsSortOpen(false);
                trackButtonClick("Sort closed", activeTab);
              }}
              selectedOption={exactSelection.selectedOption}
              updateSelection={updateExactSelection}
              activeTab={activeTab}
            />
          ) : (
            <SortPanel
              isOpen={isSortOpen}
              onClick={() => {
                setIsSortOpen(false);
                trackButtonClick("Sort closed", activeTab);
              }}
              selectedOption={similarSelection.selectedOption}
              updateSelection={updateSimilarSelection}
              activeTab={activeTab}
            />
          )}
        </>
      )}
    </WishlistProvider>
  );
}

const FloatingWishlistIcon = ({ onClick }) => {
  const { wishlist } = useContext(WishlistContext);

  return (
    <div onClick={onClick} className="floating-wishlist-icon">
      <FaHeart />
      <span className="wishlist-count">{wishlist.length}</span>
    </div>
  );
};

export default App;
