import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { WishlistContext } from "../../context/WishlistContext";
import { getExactItemByIds, getSimilarItemByIds } from "../../apiService"; // Import the API function
import "./WishlistPage.css"; // Import the new CSS file
import {
  trackButtonClick,
  usePageViews,
  useDocumentTitle,
} from "../../utils/analytics"; // Import the utility function

function WishlistPage({ onVisible }) {
  usePageViews("Wishlist Page");

  const { wishlist, removeFromWishlist } = useContext(WishlistContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state

  const fetchProducts = async () => {
    setLoading(true);
    if (wishlist.length > 0) {
      const fetchList = wishlist
        .map((item) => {
          if (!item.id) {
            console.warn("Item without ID found in wishlist:", item);
            return null;
          }
          const parts = item.id.split("-");
          if (parts.length !== 2) {
            return { prefix: "", id: item.id, timestamp: item.timestamp };
          }
          const [prefix, id] = parts;
          return { prefix, id, timestamp: item.timestamp };
        })
        .filter((item) => item !== null);

      const exactIds = fetchList
        .filter((item) => item.id.startsWith("E"))
        .map((item) => item.id);

      const similarIds = Array.from(
        new Set(
          fetchList
            .filter((item) => item.id.startsWith("S"))
            .map((item) => item.id)
        )
      );

      let fetchedExactProducts = [];
      let fetchedSimilarProducts = [];

      if (exactIds.length > 0) {
        fetchedExactProducts = await getExactItemByIds(exactIds);
      }

      if (similarIds.length > 0) {
        fetchedSimilarProducts = await getSimilarItemByIds(similarIds);
      }

      // Combine and sort the fetched products by timestamp, oldest first
      const allFetchedProducts = [
        ...fetchedExactProducts,
        ...fetchedSimilarProducts,
      ];

      // Create a map of fetched products by their IDs
      const productMap = allFetchedProducts.reduce((acc, product) => {
        acc[product.id] = product;
        return acc;
      }, {});

      const orderedProducts = fetchList
        .map((item) => {
          const product = productMap[item.id];
          return product
            ? {
                ...product,
                id: item.prefix ? `${item.prefix}-${product.id}` : product.id, // Combine id with prefix
                prefix: item.prefix,
                timestamp: item.timestamp,
              }
            : null;
        })
        .filter((product) => product !== null)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      setProducts(orderedProducts);
    } else {
      setProducts([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (onVisible) {
      fetchProducts();
    }
  }, [onVisible]);

  const handleRemove = (productId) => {
    removeFromWishlist(productId);
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product.id !== productId)
    );
  };

  const LoadingSpinner = () => (
    <div className="loading-spinner">
      <Spinner
        animation="border"
        role="status"
        variant="dark"
        style={{ width: "2rem", height: "2rem" }}
      ></Spinner>
    </div>
  );

  const numberFormatter = new Intl.NumberFormat();

  return (
    <div className="wishlist-page">
      <h2>Your Wishlist</h2>
      {loading ? (
        <LoadingSpinner />
      ) : products.length === 0 ? (
        <p>No items in wishlist.</p>
      ) : (
        products.map((product) => {
          const isSoldOut = false;
          // (product.prefix === "" && product.sold_out) ||
          // (product.prefix === "temu" && product.temu.is_sold_out) ||
          // (product.prefix === "amazon" && product.amazon.is_sold_out);

          return (
            <div
              key={product.id}
              className="wishlist-item"
              style={{ position: "relative", opacity: isSoldOut ? 0.6 : 1 }}
            >
              <button
                className="wishlist-remove-button"
                onClick={() => {
                  handleRemove(product.id);
                  trackButtonClick("Remove from Wishlist", product.id);
                }}
              >
                ✕
              </button>

              {isSoldOut && <div className="sold-out-sign">SOLD OUT</div>}

              {product.prefix === "" && (
                <div className="wishlist-row">
                  <img
                    src={product.thumbnail}
                    alt={product.id}
                    className="wishlist-thumbnail"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                      padding: "5px",
                    }}
                  />
                  <div className="wishlist-details">
                    <p className="wishlist-product-name">{product.name}</p>
                    <p className="wishlist-product-price">
                      Original Price: ${product.amazon.original_price}
                    </p>
                  </div>
                </div>
              )}
              {product.prefix === "temu" && (
                <div className="wishlist-row">
                  <img
                    src={product.temu.thumbnail}
                    alt={product.temu.id}
                    className="wishlist-thumbnail"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                      padding: "5px",
                    }}
                  />
                  <div className="wishlist-details">
                    <p className="wishlist-product-name">{product.temu.name}</p>
                    <p className="wishlist-product-price">
                      Original Price: ${product.temu.original_price}
                    </p>
                  </div>
                </div>
              )}
              {product.prefix === "amazon" && (
                <div className="wishlist-row">
                  <img
                    src={product.amazon.thumbnail}
                    alt={product.amazon.id}
                    className="wishlist-thumbnail"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                      padding: "5px",
                    }}
                  />
                  <div className="wishlist-details">
                    <p className="wishlist-product-name">
                      {product.amazon.name}
                    </p>
                    <p className="wishlist-product-price">
                      Original Price: ${product.amazon.original_price}
                    </p>
                  </div>
                </div>
              )}
              <div className="wishlist-product-source">
                {(product.prefix === "amazon" || product.prefix === "") && (
                  <a
                    href={product.amazon.affiliate_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ pointerEvents: isSoldOut ? "none" : "auto" }}
                    onClick={() =>
                      trackButtonClick("Wishlist Amazon Affiliate", product.id)
                    }
                  >
                    <div className="wishlist-amazon-container">
                      <div className="wishlist-discount-info">
                        -{product.amazon.discount_rate}
                      </div>
                      <div className="wishlist-price-info">
                        ${product.amazon.current_price}
                      </div>
                      <div className="wishlist-rating-info">
                        <span className="wishlist-star-icon">★</span>
                        {product.amazon.score}
                        <span className="wishlist-purchases">
                          (
                          {numberFormatter.format(product.amazon.review_number)}
                          )
                        </span>
                      </div>
                    </div>
                  </a>
                )}
                {(product.prefix === "temu" || product.prefix === "") && (
                  <a
                    href={product.temu.affiliate_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ pointerEvents: isSoldOut ? "none" : "auto" }}
                    onClick={() =>
                      trackButtonClick("Wishlist Temu Affiliate", product.id)
                    }
                  >
                    <div className="wishlist-temu-container">
                      <div className="wishlist-discount-info">
                        -{product.temu.discount_rate}
                      </div>
                      <div className="wishlist-price-info">
                        ${product.temu.current_price}
                      </div>
                      <div className="wishlist-rating-info">
                        <span className="wishlist-star-icon">★</span>
                        {product.temu.score}
                        <span className="wishlist-purchases">
                          ({numberFormatter.format(product.temu.review_number)})
                        </span>
                      </div>
                    </div>
                  </a>
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default WishlistPage;
