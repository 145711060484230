// src/components/SortPanel/SortPanel.js
import React, { useState, useEffect } from "react";
import "./SortPanel.css";
import SortPage from "./SortPage";
const SortPanel = ({
  isOpen,
  onClick,
  selectedOption,
  updateSelection,
  activeTab,
}) => {
  const [localSelection, setLocalSelection] = useState(selectedOption);

  // Reset localSelection when the panel is closed
  useEffect(() => {
    if (!isOpen) {
      setLocalSelection(selectedOption);
    }
  }, [isOpen, selectedOption]);

  const handleApply = () => {
    updateSelection({ selectedOption: localSelection });
    onClick(); // Close the panel
  };

  return (
    <div
      className={`sort-modal-overlay ${isOpen ? "open" : ""}`}
      onClick={onClick}
    >
      <div className="sort-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClick}>
          &times;
        </button>
        <button className="apply-button" onClick={handleApply}>
          Apply
        </button>
        <SortPage
          selectedOption={localSelection}
          updateSelection={setLocalSelection}
          activeTab={activeTab}
        />
      </div>
    </div>
  );
};

export default SortPanel;
