import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { postTemuAffiliateLink, postAmazonAffiliateLink } from "../apiService";

// Function to get local time in a readable format
function getLocalTime() {
  const now = new Date();
  return now.toLocaleString(); // Returns the local time as a string
}

// Function to get local region
function getLocalRegion() {
  const region = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  return region; // Returns the local region (e.g., "America/Los_Angeles")
}

export const trackButtonClick = (action, trackingInfo) => {
  ReactGA.event({
    category: "Button",
    action: action,
    label: trackingInfo,
  });
};

export const trackAffiliateClick = (action, id, name, link) => {
  ReactGA.event({
    category: "Button",
    action: action,
    label: id,
  });

  if (
    action === "Similar Temu Affiliate" ||
    action === "Exact Temu Affiliate"
  ) {
    setTimeout(() => {
      postTemuAffiliateLink(
        action,
        id,
        link,
        name,
        getLocalTime(),
        getLocalRegion()
      );
    }, 0);
  } else if (
    action === "Similar Amazon Affiliate" ||
    action === "Exact Amazon Affiliate"
  ) {
    setTimeout(() => {
      postAmazonAffiliateLink(
        action,
        id,
        link,
        name,
        getLocalTime(),
        getLocalRegion()
      );
    }, 0);
  }
};

export function usePageViews(pageTitle) {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: pageTitle,
    });
  }, [location]);
}

export const trackComponentDisplay = (componentName, additionalData = {}) => {
  ReactGA.event({
    category: "Component",
    action: "Display",
    label: componentName,
    ...additionalData,
  });
};

export const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};
