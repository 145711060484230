import React from "react";
import SimilarComparison from "./SimilarComparison/SimilarComparison";
import "./SimilarComparison/SimilarComparison.css";
import { usePageViews, useDocumentTitle } from "../utils/analytics";
import { Row, Col, Card } from "react-bootstrap";

function SimilarPage({ products, lastProductElementRef, isFetchingSimilar }) {
  usePageViews("Similar Page");

  return (
    <div className="similar-comparison-container">
      {!isFetchingSimilar && products.length === 0 ? (
        <p className="no-products-message">
          No products available. Please check back later or change your filter
          options.
        </p>
      ) : (
        products.map((product, index) => {
          const isLastElement = products.length === index + 1;

          return (
            <div
              className="similar-comparison"
              ref={isLastElement ? lastProductElementRef : null}
              key={`product-${product.id}`}
            >
              <Row
                className={`justify-content-center mb-5 ${
                  false ? "similar-sold-out" : ""
                }`}
              >
                <SimilarComparison
                  ref={isLastElement ? lastProductElementRef : null}
                  id={`amazon-${product.id}`}
                  key={`amazon-${product.id}`}
                  source="amazon"
                  name={product.amazon.name}
                  thumbnail={product.amazon.thumbnail}
                  category={product.category}
                  currentPrice={product.amazon.current_price}
                  originalPrice={product.amazon.original_price}
                  discount={product.amazon.discount_rate}
                  rating={product.amazon.score}
                  purchases={product.amazon.review_number}
                  soldout={false}
                  affiliateLink={product.amazon.affiliate_link}
                  amazonNewPrice={product.amazon.price_new}
                  amazonUsedPrice={product.amazon.price_used}
                />
                <SimilarComparison
                  ref={isLastElement ? lastProductElementRef : null}
                  id={`temu-${product.id}`}
                  key={`temu-${product.id}`}
                  source="temu"
                  name={product.temu.name}
                  thumbnail={product.temu.thumbnail}
                  category={product.category}
                  currentPrice={product.temu.current_price}
                  originalPrice={product.temu.original_price}
                  discount={product.temu.discount_rate}
                  rating={product.temu.score}
                  purchases={product.temu.review_number}
                  soldout={false}
                  affiliateLink={product.temu.affiliate_link}
                />
              </Row>
            </div>
          );
        })
      )}
    </div>
  );
}

export default SimilarPage;
