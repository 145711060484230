// src/components/WishlistModal.js
import React, { useEffect, useState, useRef } from "react";
import WishlistPage from "./WishlistPage";

const WishlistModal = ({ isOpen, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (modalRef.current) {
      observer.observe(modalRef.current);
    }

    return () => {
      if (modalRef.current) {
        observer.unobserve(modalRef.current);
      }
    };
  }, [modalRef]);

  return (
    <div
      className={`wishlist-modal-overlay ${isOpen ? "open" : ""}`}
      onClick={onClose}
    >
      <div
        className="wishlist-modal"
        onClick={(e) => e.stopPropagation()}
        ref={modalRef}
      >
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <WishlistPage onVisible={isVisible} />
      </div>
    </div>
  );
};

export default WishlistModal;
